<template>
    <input
        ref="autocomplete"
        :placeholder="placeholder"
        class="input"
        type="search"
        :value="value"
        required
        @input="$emit('clean-result', $event.target.value)"
    />
</template>

<script>
import '../googlemaps'

export default {
    name: 'GoogleMapsAutocomplete',
    props: {
        placeholder: String,
        labelPosition: String,
        label: String,
        countries: Array,
        types: Array,
        value: String,
        required: Boolean
    },
    methods: {
        extractAddress(place) {
            return place.address_components.reduce((base, next) => {
                base[next['types'][0]] = next.long_name
                return base
            }, [])
        },
        initLocationSearch() {
            this.autocomplete = new window.google.maps.places.Autocomplete(
                this.$refs.autocomplete,
                {
                    types: this.types,
                    componentRestrictions: { country: this.countries },
                    fields: [
                        'address_components',
                        'adr_address',
                        'formatted_address',
                        'geometry.location',
                        'place_id'
                    ]
                }
            )

            this.autocomplete.addListener('place_changed', () => {
                let place = this.autocomplete.getPlace()

                if (place) {
                    if (place.geometry) {
                        var lat = place.geometry.location.lat()
                        var lng = place.geometry.location.lng()
                    }

                    let result = {
                        address: this.extractAddress(place),
                        lat: lat,
                        lng: lng,
                        fullDetails: place
                    }

                    this.$emit('place-changed', result)
                }
            })
        }
    },
    mounted() {
        window.checkAndAttachMapScript(this.initLocationSearch)
    }
}
</script>
