<template>
    <form @submit.prevent="checkForm">
        <div class="columns is-multiline">
            <b-loading :is-full-page="true" :active.sync="isLoadingBooking" />
            <div v-if="isSeller" class="column is-12-desktop">
                <b-field
                    v-if="isSeller"
                    :label="$t('booking.dateAndTime.label')"
                    label-position="inside"
                >
                    <b-datetimepicker
                        v-model="booking.confirmedDate"
                        :placeholder="$t('booking.dateAndTime.placeholder')"
                        icon="calendar-today"
                        required
                        editable
                        :min-datetime="today"
                        :datepicker="{
                            showWeekNumber: true,
                            firstDayOfWeek: 1
                        }"
                        :timepicker="{
                            enableSeconds: false,
                            hourFormat: '24',
                            incrementMinutes: 15
                        }"
                    />
                </b-field>
            </div>
            <template v-else>
                <div class="column is-6-desktop">
                    <b-field
                        :label="$t('booking.startDate.label')"
                        label-position="inside"
                        expanded
                    >
                        <b-datetimepicker
                            v-model="booking.reservationFrom"
                            :placeholder="$t('booking.startDate.placeholder')"
                            icon="calendar-today"
                            required
                            editable
                            :min-datetime="today"
                            first-day-of-week="1"
                            min-time="08:00"
                            max-time="19:00"
                            :datepicker="{
                                showWeekNumber: true,
                                firstDayOfWeek: 1
                            }"
                            :timepicker="{
                                enableSeconds: false,
                                hourFormat: '24',
                                incrementMinutes: 15
                            }"
                        />
                    </b-field>
                </div>
                <div class="column is-6-desktop">
                    <b-field
                        :label="$t('booking.endDate.label')"
                        label-position="inside"
                        expanded
                    >
                        <b-datetimepicker
                            v-model="booking.reservationTo"
                            :placeholder="$t('booking.endDate.placeholder')"
                            icon="calendar-today"
                            required
                            editable
                            min-time="08:00"
                            max-time="19:00"
                            :min-datetime="booking.reservationFrom"
                            :datepicker="{
                                showWeekNumber: true,
                                firstDayOfWeek: 1
                            }"
                            :timepicker="{
                                enableSeconds: false,
                                hourFormat: '24',
                                incrementMinutes: 15
                            }"
                        />
                    </b-field>
                </div>
            </template>
            <div class="column is-6-desktop">
                <b-field
                    :label="
                        isSeller
                            ? $t('booking.customer.label')
                            : $t('booking.photographer.label')
                    "
                    label-position="inside"
                    expanded
                >
                    <b-autocomplete
                        v-model="customerName"
                        icon="account-search-outline"
                        :data="filteredUsers"
                        :placeholder="
                            isSeller
                                ? $t('booking.customer.placeholder')
                                : $t('booking.photographer.placeholder')
                        "
                        field="fullName"
                        :loading="isLoadingCustomer"
                        :open-on-focus="true"
                        required
                        clearable
                        @select="option => selectUser(option)"
                    >
                        <template slot-scope="props">
                            <div class="content">
                                <h3 class="title is-5">
                                    {{ props.option.fullName }}
                                </h3>
                                <p class="subtitle is-7">
                                    {{ props.option.group }}
                                </p>
                            </div>
                        </template>
                    </b-autocomplete>
                </b-field>
            </div>
            <div class="column is-6-desktop">
                <b-field
                    :label="$t('booking.product.label')"
                    label-position="inside"
                    expanded
                >
                    <b-select
                        v-model="booking.product"
                        :placeholder="$t('booking.product.placeholder')"
                        :loading="isLoadingProduct"
                        expanded
                        required
                        icon="briefcase-plus-outline"
                        :disabled="!booking.target || !hasProducts"
                    >
                        <option
                            v-for="p in products"
                            :key="p.uuid"
                            :value="p['@id']"
                        >
                            {{ p.name }} ({{ p.price | round }} SEK)
                        </option>
                    </b-select>
                </b-field>
            </div>

            <div class="column is-6-desktop">
                <b-field
                    :label="$t('booking.location.label')"
                    label-position="inside"
                    expanded
                >
                    <div class="control has-icons-left is-clearfix">
                        <GoogleMapsAutocomplete
                            v-model="searchedAddress"
                            :value="searchedAddress"
                            :countries="['se']"
                            :types="['geocode']"
                            :placeholder="$t('booking.location.placeholder')"
                            :required="true"
                            @clean-result="cleanAddressResult($event)"
                            @place-changed="
                                googleMapsPlaceChanged($event, 'address')
                            "
                        />
                        <span class="icon is-left">
                            <i class="mdi mdi-map-marker-outline mdi-24px" />
                        </span>
                    </div>
                </b-field>
            </div>

            <div class="column is-6-desktop">
                <b-field
                    :label="$t('booking.endCustomer.label')"
                    label-position="inside"
                    expanded
                >
                    <b-input
                        v-model="booking.name"
                        :placeholder="$t('booking.endCustomer.placeholder')"
                        icon="account-question-outline"
                        :type="errors.name ? 'is-danger' : ''"
                        :message="errors.name"
                        required
                    />
                </b-field>
            </div>

            <div class="column is-4-desktop">
                <b-field
                    :label="$t('booking.size.label')"
                    label-position="inside"
                >
                    <b-input
                        v-model.number="booking.size"
                        type="number"
                        :placeholder="$t('booking.size.placeholder')"
                        icon="image-size-select-small"
                        required
                    />
                </b-field>
            </div>

            <div class="column is-4-desktop">
                <b-field
                    :label="$t('booking.customerPhone.label')"
                    label-position="inside"
                    expanded
                >
                    <b-input
                        v-model="booking.phone"
                        type="tel"
                        :placeholder="$t('booking.customerPhone.placeholder')"
                        icon="contact-phone-outline"
                    />
                </b-field>
            </div>

            <div class="column is-4-desktop">
                <b-field
                    :label="$t('booking.doorCode.label')"
                    label-position="inside"
                >
                    <b-input
                        v-model="booking.doorcode"
                        :placeholder="$t('booking.doorCode.placeholder')"
                        icon="door-closed-lock"
                    />
                </b-field>
            </div>

            <div class="column is-12-desktop">
                <b-field
                    :label="$t('booking.notes.label')"
                    label-position="inside"
                    expanded
                >
                    <b-input
                        v-model="booking.note"
                        maxlength="200"
                        :placeholder="$t('booking.notes.placeholder')"
                        type="textarea"
                    />
                </b-field>
            </div>
        </div>

        <div class="buttons is-pulled-right">
            <b-button
                v-if="existingBooking"
                type="is-info"
                outlined
                @click="finishBooking('done')"
            >
                {{ $t('booking.buttons.markAsDone') }}
            </b-button>
            <b-button
                v-if="existingBooking"
                type="is-warning"
                outlined
                @click="finishBooking('delivered')"
            >
                {{ $t('booking.buttons.deliverToCustomer') }}
            </b-button>
            <b-button
                :type="!completeBooking ? 'is-default' : 'is-success'"
                outlined
                :disabled="!completeBooking"
                @click="createBooking"
            >
                <span v-if="existingBooking">{{
                    $t('booking.buttons.update')
                }}</span>
                <span v-else-if="!completeBooking">
                    {{ $t('booking.create.missingFields') }}
                </span>
                <span v-else>
                    {{ $t('booking.buttons.book') }}
                </span>
            </b-button>
        </div>
    </form>
</template>

<script>
import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete'

export default {
    name: 'BookingMobile',
    components: { GoogleMapsAutocomplete },
    props: { existingBooking: Object },
    data() {
        return {
            activeStep: 0,
            customerName: '',
            tempdate: null,
            errors: [],
            user: null,
            address: [],
            place: null,
            searchedAddress: '',
            booking: {
                product: '',
                target: '',
                confirmedDate: null,
                reservationFrom: null,
                reservationTo: null,
                locationRoute: null,
                locationStreetNumber: null,
                locationPostalCode: null,
                locationPostalTown: null,
                locationCountry: null,
                locationLat: null,
                locationLong: null,
                name: null,
                phone: null,
                size: null,
                note: null,
                doorcode: null
            },
            productLoading: false,
            formReset: null
        }
    },
    computed: {
        today() {
            var today = new Date()
            return new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            )
        },
        error() {
            if (this.hasErrorProduct) {
                return this.$store.getters['product/error']
            }

            if (this.hasErrorCustomer) {
                return this.$store.getters['usergroup/error']
            }

            return this.$store.getters['booking/error']
        },
        hasErrorBooking() {
            return this.$store.getters['booking/hasError']
        },
        isLoadingProduct() {
            return this.$store.getters['product/isLoading']
        },
        hasErrorProduct() {
            return this.$store.getters['product/hasError']
        },
        hasProducts() {
            return this.$store.getters['product/hasProducts']
        },
        products() {
            return this.$store.getters['product/products']
        },
        isLoadingCustomer() {
            return this.$store.getters['usergroup/isLoading']
        },
        hasErrorCustomer() {
            return this.$store.getters['usergroup/hasError']
        },
        hasCustomers() {
            return this.$store.getters['usergroup/hasUsergroups']
        },
        customers() {
            return this.$store.getters['usergroup/usergroups']
        },
        isLoadingBooking() {
            return this.$store.getters['booking/isLoading']
        },
        isSeller() {
            return this.$store.getters['security/hasRole']('ROLE_SELLER')
        },
        filteredUsers() {
            return this.cleanedCustomers
                .filter(option => {
                    return (
                        option.fullSearch
                            .toString()
                            .toLowerCase()
                            .indexOf(this.customerName.toLowerCase()) >= 0
                    )
                })
                .sort(function(a, b) {
                    var nameA = a.fullSearch.toUpperCase()
                    var nameB = b.fullSearch.toUpperCase()
                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return 0
                })
        },
        cleanedCustomers() {
            return this.customers.reduce((base, customer) => {
                customer.members.forEach(user => {
                    base.push({
                        id: customer['@id'],
                        group: customer.name,
                        usergroupUuid: customer.uuid,
                        fullName: user.fullName,
                        fullSearch: user.fullName + ', ' + customer.name,
                        userUuid: user.uuid,
                        userIri: user['@id']
                    })
                })
                return base
            }, [])
        },
        completeBooking() {
            if (
                this.booking.product &&
                this.booking.target &&
                this.booking.locationRoute &&
                this.searchedAddress
            ) {
                return true
            }
            return false
        },
        checkForErrors() {
            return this.hasErrorBooking ||
                this.hasErrorCustomer ||
                this.hasErrorProduct
                ? console.log(this.error)
                : ''
        }
    },
    methods: {
        cleanAddressResult(event) {
            this.searchedAddress = event
            this.address.route = ''
            this.booking.locationRoute = null
            this.booking.locationStreetNumber = null
            this.booking.locationPostalTown = null
            this.booking.locationPostalCode = null
            this.booking.locationCountry = null
            this.booking.locationLat = null
            this.booking.locationLong = null
        },
        selectUser(user) {
            if (user) {
                this.customerName = user.fullName
                this.$store.dispatch('product/listAll', user.usergroupUuid)
                this.booking.target = user.userIri
            } else {
                this.booking.target = null
            }
        },
        /*
            <b-datetimepicker
                    v-model="booking.confirmedDate"
                    rounded
                    placeholder="Click to select..."
                    icon="calendar-today"
                    :datepicker="{ showWeekNumber: true }"
                    :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                />
        }, */
        googleMapsPlaceChanged($event, type) {
            this.address.route = $event.address.route
            this.booking.locationRoute = $event.address.route
            this.booking.locationStreetNumber = $event.address.street_number
            this.booking.locationPostalTown = $event.address.postal_town
            this.booking.locationPostalCode = $event.address.postal_code
            this.booking.locationCountry = $event.address.country
            this.booking.locationLat = String($event.lat)
            this.booking.locationLong = String($event.lng)
        },
        resetForm() {
            this.booking = { ...this.formReset }
        },
        checkForm: function() {
            alert('Check!')
        },
        async createBooking() {
            if (this.existingBooking) {
                await this.$store.dispatch('booking/update', this.booking)
                return
            }

            if (!this.address.route) {
                await this.$buefy.dialog.confirm({
                    title: this.$t('booking.location.noMatchTitle'),
                    message: this.$t('booking.location.noMatchMessage', {
                        address: this.searchedAddress
                    }),
                    confirmText: this.$t(
                        'booking.location.noMatchContinueButton'
                    ),
                    cancelText: this.$t('booking.location.noMatchCancelButton'),
                    type: 'is-warning',
                    hasIcon: true,
                    icon: 'map-search',
                    onConfirm: () => {
                        this.booking.locationRoute = this.searchedAddress
                    }
                })
            }

            if (this.completeBooking) {
                const result = await this.$store.dispatch(
                    'booking/create',
                    this.$data.booking
                )
                // console.log(result)
                if (result !== null) {
                    /*
                    this.$buefy.toast.open({
                        message: this.$t('booking.create.success', {
                            address: result.fullAddress
                        }),
                        type: 'is-success'
                    })
                    */
                    this.$emit('booking-complete', result)
                    // this.$data.booking = []
                }
                // console.log(result)
            } else {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('booking.create.missingFields'),
                    position: 'is-bottom',
                    type: 'is-danger'
                })
            }
        },
        async finishBooking(state) {
            this.booking = { ...this.booking, state: state }

            await this.$store.dispatch('booking/update', this.booking)
        }
    },
    created() {
        // this.user = this.$store.getters['security/hasRole']('ROLE_SELLER')
        // TODO: Maybe add ROLE_ check here to fetch customers/sellers instead of all
        this.$store.dispatch('usergroup/listAll')
        this.resetForm = { ...this.booking }

        if (this.$route.params.calendar) {
            this.booking.confirmedDate = this.$route.params.calendar.start
        } else if (this.existingBooking) {
            var target = this.isSeller
                ? this.existingBooking.customer['@id']
                : this.existingBooking.owner['@id']

            this.customerName = this.isSeller
                ? this.existingBooking.customer.fullName
                : this.existingBooking.owner.fullName

            this.$store.dispatch('product/listAll', target)

            let confirmedDate = this.existingBooking.confirmedDate
                ? new Date(this.existingBooking.confirmedDate)
                : null

            let reservationFrom = this.existingBooking.reservationFrom
                ? new Date(this.existingBooking.reservationFrom)
                : null

            let reservationTo = this.existingBooking.reservationTo
                ? new Date(this.existingBooking.reservationTo)
                : null

            this.booking = {
                '@id': this.existingBooking['@id'],
                product: this.existingBooking.product['@id'],
                target: target,
                confirmedDate: confirmedDate,
                reservationFrom: reservationFrom,
                reservationTo: reservationTo,
                locationRoute: this.existingBooking.locationRoute,
                locationStreetNumber: this.existingBooking.locationStreetNumber,
                locationPostalCode: this.existingBooking.locationPostalCode,
                locationPostalTown: this.existingBooking.locationPostalTown,
                locationCountry: this.existingBooking.locationCountry,
                locationLat: this.existingBooking.locationLat,
                locationLong: this.existingBooking.locationLong,
                name: this.existingBooking.name,
                phone: this.existingBooking.phone,
                size: this.existingBooking.size,
                note: this.existingBooking.note,
                doorcode: this.existingBooking.doorcode
            }

            this.searchedAddress = this.existingBooking.fullAddress
            // console.log(this.searchedAddress)
        }
    }
}
</script>
