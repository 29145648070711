window.checkAndAttachMapScript = function(callback) {
    if (window.google) {
        callback()
        return true
    }
    // if (document.getElementById('google')) return true
    window.mapApiInitialized = callback
    let script = document.createElement('script')
    script.id = 'google'
    script.src =
        'https://maps.googleapis.com/maps/api/js?key=' +
        process.env.VUE_APP_GOOGLE_API_KEY +
        ' &libraries=places&callback=mapApiInitialized'
    script.defer = true
    document.body.appendChild(script)
}
